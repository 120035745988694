import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

import NotFound from "@/app/dashboard/views/NotFound.vue";
import homeRoutes from "./routes/home";
import communityRoutes from "./routes/community";
import { authRoutes, impersonationRoutes } from "./routes/auth";
import appConfigurationRoutes from "./routes/app-configuration";
import { dataExportRoutes } from "./routes/data-export";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...authRoutes,
    {
      path: "/",
      component: () => import("@/app/dashboard/views/Dashboard.vue"),
      children: [
        { path: "/", redirect: "/home" },
        ...impersonationRoutes,
        ...homeRoutes,
        ...communityRoutes,
        ...appConfigurationRoutes,
        ...dataExportRoutes,
        {
          path: "*",
          name: "not-found",
          component: NotFound,
        },
      ],
    },
    {
      path: "*",
      redirect: {
        name: "not-found",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && to.name !== "auth-callback" && !store.state.auth.user) {
    store.commit("setLoginRedirect", to.fullPath);
    return next({ name: "login" });
  }
  return next();
});

export default router;

export function useRouter() {
  return router;
}
