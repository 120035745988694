/* eslint-disable no-param-reassign */
export default {
  namespaced: true,

  state() {
    return {
      loading: false,
      downloads: 0,
      rank: 0,
      rankCategory: "",
      rating: 0,
    };
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setDownloads(state, downloads) {
      state.downloads = downloads;
    },

    setRank(state, rank) {
      state.rank = rank;
    },

    setRankCategory(state, category) {
      state.rankCategory = category;
    },

    setRating(state, rating) {
      state.rating = rating;
    },
  },
};
