import Vue from "vue";
import App from "./App.vue";
import "./plugins/global-components";
import "./plugins/constants";
import "./plugins/pixl";
import "./plugins/http";
import i18n from "./plugins/i18n";
import "./plugins/logger";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import wait from "./plugins/wait";
import router from "./router";
import store from "./store";

import "./styles/_index.scss";

Vue.config.productionTip = false;

new Vue({
  name: "Vue",
  router,
  store,
  i18n,
  vuetify,
  wait,
  render: (h) => h(App),
}).$mount("#app");
