export const constants = {
  appConfiguration: {
    cardFormats: {
      verticalSmall: {
        width: 100,
        height: 140,
      },
      verticalMedium: {
        width: 140,
        height: 196,
      },
      verticalTall: {
        width: 140,
        height: 240,
      },
      verticalBig: {
        width: 140,
        height: 240,
      },
      horizontalSmall: {
        width: 330,
        height: 140,
      },
      horizontalMedium: {
        width: 330,
        height: 170,
      },
      horizontalTall: {
        width: 330,
        height: 200,
      },
      horizontalBig: {
        width: 330,
        height: 200,
      },
    },
  },
};
