import Vue from "vue";
import logger from "@/services/logger.service";

Vue.prototype.$logger = logger;

declare module "vue/types/vue" {
  interface Vue {
    $logger: typeof logger;
  }
}

export function useLogger() {
  return logger;
}
