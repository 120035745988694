import { RouteConfig } from "vue-router";

const appConfigurationRoutes: RouteConfig[] = [
  {
    path: "/app-configuration/parameters",
    name: "app-configuration-parameters",
    props: true,
    component: () => import("@/app/app-configuration/parameters/views/AppParameters.vue"),
    meta: {
      title: "app.navigation.app_configuration_parameters",
      group: "app-configuration",
    },
  },
  {
    path: "/app-configuration/countries",
    name: "app-configuration-countries",
    props: true,
    component: () => import("@/app/app-configuration/country-list/views/CountryList.vue"),
    meta: {
      title: "app.navigation.app_configuration_countries",
      group: "app-configuration",
    },
  },
  {
    path: "/app-configuration/catalog",
    name: "app-configuration-catalog",
    redirect: { name: "app-configuration-countries" },
    meta: {
      title: "app.navigation.app_configuration_catalog",
      group: "app-configuration",
    },
  },
  {
    path: "/app-configuration/catalog/:countryCode",
    redirect: { name: "app-configuration-catalog-country" },
    props: true,
    meta: {
      title: "app.navigation.app_configuration_catalog",
      group: "app-configuration",
    },
  },
  {
    path: "/app-configuration/countries/:countryCode/catalog",
    name: "app-configuration-catalog-country",
    component: () => import("@/app/app-configuration/catalog-home/views/Editor.vue"),
    props: true,
    meta: {
      title: "app.navigation.app_configuration_catalog",
      group: "app-configuration",
    },
  },
  {
    path: "/app-configuration/homefeed",
    name: "app-configuration-homefeed",
    redirect: { name: "app-configuration-countries" },
    meta: {
      title: "app.navigation.app_configuration_homefeed",
      group: "app-configuration",
    },
  },
  {
    path: "/app-configuration/countries/:countryCode/homefeed",
    name: "app-configuration-homefeed-country",
    component: () => import("@/app/app-configuration/homefeed/views/HomefeedEditor.vue"),
    props: (r) => {
      return {
        countryCode: r.params.countryCode.toUpperCase(),
      };
    },
    meta: {
      title: "app.navigation.app_configuration_homefeed",
      group: "app-configuration",
    },
  },
];

export default appConfigurationRoutes;
