import isValidSemverVersion from "semver/functions/valid";
import { configure, extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  integer,
  max,
  max_value,
  min,
  min_value,
  required,
  alpha_dash,
  regex,
} from "vee-validate/dist/rules";
import Vue from "vue";
import i18n from "./i18n";

extend("required", required);
extend("min", min);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);
extend("integer", integer);
extend("alpha_dash", alpha_dash);
extend("regex", regex);

extend("semver", {
  message: (field) =>
    `Le champ ${field} doit être un numéro de version valide dans la norme SemVer`,
  validate: (value) => typeof isValidSemverVersion(value) === "string",
});

configure({
  defaultMessage: (_, values) => i18n.t(`validations.${values?._rule_}`, values) as string,
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
