import get from "lodash/get";

const TeamMemberRoles = ["ADMIN", "TEAM_MEMBER"];

/**
 * @typedef {{ user?: object, onLoginRedirect?: string }} AuthStore
 */

export default /** @type { import("vuex").StoreOptions<AuthStore>} */ ({
  state: {
    user: undefined,
    onLoginRedirect: localStorage.getItem("login_redirect"),
  },

  getters: {
    user: (state) => state.user,
    isAdmin: (state) => get(state, "user.role") === "ADMIN",
    isTeamMember: (state) => TeamMemberRoles.includes(get(state, "user.role")),
  },

  mutations: {
    setUser: (state, user) => {
      state.user = { ...user };
    },

    setLoginRedirect: (state, url) => {
      state.onLoginRedirect = url;
      if (url) {
        localStorage.setItem("login_redirect", url);
      } else {
        localStorage.removeItem("login_redirect");
      }
    },
  },
});
