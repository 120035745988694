import Vue from "vue";
import { constants } from "@/app/app-configuration/shared/constants";

Vue.prototype.$constants = constants;

declare module "vue/types/vue" {
  interface Vue {
    $constants: typeof constants;
  }
}
