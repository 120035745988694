<template>
  <router-view />
</template>

<script lang="ts">
import Vue from "vue";
import get from "lodash/get";
import { mapState, mapMutations } from "vuex";

export default Vue.extend({
  name: "App",

  computed: {
    ...mapState({ user: (state: any) => state.auth.user }),
    ...mapState("userPreferences", ["locale"]),
  },

  watch: {
    user() {
      if (this.user && this.user.preferredLanguage && !this.locale) {
        this.$i18n.locale = this.user.preferredLanguage;
      }
    },

    "$i18n.locale"(locale: string) {
      this.setLocale(locale);
    },

    "$vuetify.theme.dark"(isDark: boolean) {
      this.$logger.debug("switching dark mode");
      localStorage.setItem("darkMode", String(isDark));
    },
  },

  created() {
    this.$vuetify.theme.dark = localStorage.getItem("darkMode") === "true";

    if (this.locale) {
      this.$i18n.locale = this.locale;
    } else if (
      this.user &&
      this.user.preferredLanguage &&
      this.$i18n.availableLocales.includes(this.user.preferredLanguage)
    ) {
      this.$i18n.locale = this.user.preferredLanguage;
    } else {
      try {
        const browserLocale = get(navigator, "language", "en").split("-")[0].toLowerCase();
        this.$i18n.locale = this.$i18n.availableLocales.includes(browserLocale)
          ? browserLocale
          : "en";
      } catch {
        this.$i18n.locale = "en";
      }
    }
  },

  methods: {
    ...mapMutations("userPreferences", ["setLocale"]),
  },
});
</script>
