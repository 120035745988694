import { RouteConfig } from "vue-router";

const homeRoutes: RouteConfig[] = [
  {
    path: "/home",
    component: () => import(/* webpackChunkName: "home" */ "@/app/home/views/Home.vue"),
    children: [
      {
        path: "",
        redirect: "community-active",
        meta: {
          title: "app.navigation.home",
        },
      },
      {
        path: "community-total",
        name: "home-community-total",
        component: () => import("@/app/home/views/CommunityDetail.vue"),
        props: { mode: "total" },
        meta: {
          title: "app.navigation.home",
        },
      },
      {
        path: "community-active",
        name: "home-community-active",
        component: () => import("@/app/home/views/CommunityDetail.vue"),
        props: { mode: "active" },
        meta: {
          title: "app.navigation.home",
        },
      },
      {
        path: "app-revenue",
        name: "home-app-revenue",
        component: () => import("@/app/home/views/RevenueDetail.vue"),
        meta: {
          title: "app.navigation.home",
        },
      },
      {
        path: "app-android",
        name: "home-app-android",
        meta: {
          title: "app.navigation.home",
        },
      },
      {
        path: "app-ios",
        name: "home-app-ios",
        meta: {
          title: "app.navigation.home",
        },
      },
    ],
  },
];

export default homeRoutes;
