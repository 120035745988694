import slugify from "@sindresorhus/slugify";
import Vue from "vue";

interface GenerateImageUrlOptions {
  crop?: [number, number, number, number];
  width?: number;
  height?: number;
  name?: string;
  format?: string;
  squared?: boolean;
}

const $pixl = {
  generateImageUrl(id: string, signature: string, options: GenerateImageUrlOptions = {}): string {
    let size = "600x600";

    if (options.width || options.height) {
      size = `${options.width || 0}x${options.height || 0}`;
    }

    const urlParts: string[] = ["https://contents.mediadecathlon.com", id, size];

    if (options.squared) {
      urlParts.push("sq");
    }

    if (Array.isArray(options.crop) && options.crop.length === 4) {
      const [focusX, focusY, width, height] = options.crop;
      urlParts.push(`${focusX}pt${focusY}`, `${width}xcr${height}`);
    }

    urlParts.push(
      `k$${signature}`,
      `${encodeURIComponent(slugify(options.name || `${id}-default`))}.${options.format || "jpg"}`
    );

    return urlParts.join("/");
  },

  parseUrl(url: string): { pixlId: string; pixlSignature: string } | undefined {
    const parsed = url.match(/\/([pbs][0-9]+).*\/k\$([0-9a-z]+).*/i);
    if (parsed && parsed.length >= 3) {
      return {
        pixlId: parsed[1],
        pixlSignature: parsed[2],
      };
    }

    return undefined;
  },
};

declare module "vue/types/vue" {
  interface Vue {
    $pixl: typeof $pixl;
  }
}

Vue.prototype.$pixl = $pixl;
