/* eslint-disable no-param-reassign */
export default {
  namespaced: true,

  state: {
    fullStoreList: [],
    fullRegionList: [],
    countries: [],
    regions: [],
    stores: [],
    selectedCountries: [],
    selectedRegions: [],
    selectedStores: [],
  },

  getters: {
    selectedCountriesCount({ selectedCountries }) {
      return selectedCountries.length;
    },

    selectedRegionsCount({ selectedRegions }) {
      return selectedRegions.length;
    },

    selectedStoresCount({ selectedStores }) {
      return selectedStores.length;
    },

    findStoreById: (state) => (id) => state.stores.find((store) => store.id === id),
  },

  mutations: {
    setFullStoreList(state, payload) {
      state.fullStoreList = payload;
    },

    setFullRegionList(state, payload) {
      state.fullRegionList = payload;
    },

    setCountries(state, countries) {
      state.countries = countries;
    },

    setRegions(state, regions) {
      state.regions = regions;
    },

    setStores(state, stores) {
      state.stores = stores;
    },

    setSelectedCountries(state, countries) {
      state.selectedCountries = countries;
    },

    setSelectedRegions(state, regions) {
      state.selectedRegions = regions;
    },

    setSelectedStores(state, stores) {
      state.selectedStores = stores;
    },
  },
};
