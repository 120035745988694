/** @type {import("vuex").Module} */
const SportsModule = {
  namespaced: true,

  state: {
    disciplines: [],
    selectedDisciplines: [],
    sports: [],
    selectedSports: [],
  },

  mutations: {
    setDisciplines(state, disciplines) {
      state.disciplines = disciplines;
      if (state.selectedDisciplines.length) {
        state.selectedDisciplines = state.selectedDisciplines.filter((discipline) =>
          disciplines.some((d) => d.id === discipline.id)
        );
      }
    },

    setSelectedDisciplines(state, disciplines) {
      state.selectedDisciplines = disciplines;
    },

    setSports(state, sports) {
      state.sports = sports;
      if (state.selectedSports.length) {
        state.selectedSports = state.selectedSports.filter((sport) =>
          sports.some((s) => s.id === sport.id)
        );
      }
    },

    setSelectedSports(state, sports) {
      state.selectedSports = sports;
    },
  },
};

export default SportsModule;
