/* eslint-disable no-param-reassign */
export default {
  namespaced: true,

  state: {
    currentFrom: null,
    currentTo: null,
    comparisonFrom: null,
    comparisonTo: null,
    periodKey: "anytime",
    comparisonIndex: 0,
  },

  mutations: {
    setSelectedPeriod(state, period) {
      state.currentFrom = period.dateFrom;
      state.currentTo = period.dateTo;
      state.periodKey = period.key;
    },

    setComparisonPeriod(state, period) {
      state.comparisonFrom = period.dateFrom;
      state.comparisonTo = period.dateTo;
      state.comparisonIndex = period.index;
    },
  },
};
