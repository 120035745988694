export default {
  namespaced: true,

  state() {
    let locale = localStorage.getItem("locale");
    if (locale) {
      locale = locale.toLowerCase();
    }

    return {
      locale,
      devMode: localStorage.getItem("devMode") === "true",
    };
  },

  mutations: {
    setLocale(state, locale) {
      localStorage.setItem("locale", locale);
      state.locale = locale;
    },

    setDevMode(state, enabled) {
      localStorage.setItem("devMode", enabled);
      state.devMode = enabled;
    },
  },
};
