import Vue from "vue";
import Vuex from "vuex";
import ApplicationModule from "./modules/static/application";
import AuthModule from "./modules/static/auth";
import CommunityModule from "./modules/static/community";
import DateFiltersModule from "./modules/static/date-filters";
import LocationFiltersModule from "./modules/static/location-filters";
import RevenueModule from "./modules/static/revenue";
import UserPreferencesModule from "./modules/static/user-preferences";
import SportsModule from "./modules/static/sports";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    ready: false,
  },

  mutations: {
    setReady: (state) => {
      state.ready = true;
    },
  },

  modules: {
    auth: AuthModule,
    userPreferences: UserPreferencesModule,
    locationFilters: LocationFiltersModule,
    dateFilters: DateFiltersModule,
    sports: SportsModule,

    communityTotal: CommunityModule,
    communityTotalComparison: CommunityModule,
    communityActive: CommunityModule,
    communityActiveComparison: CommunityModule,
    android: ApplicationModule,
    ios: ApplicationModule,
    revenue: RevenueModule,
    revenueComparison: RevenueModule,
  },
});

export default store;

export function useStore() {
  return store;
}
