import Vue from "vue";
import http from "@/services/http.service";

Vue.prototype.$http = http;

declare module "vue/types/vue" {
  interface Vue {
    $http: typeof http;
  }
}
