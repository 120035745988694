<template>
  <transition name="fade-transition">
    <v-overlay
      z-index="20"
      :absolute="absolute"
      opacity="0.6"
    >
      <Spinner
        :animation-duration="1500"
        :size="50"
        :color="spinnerColor"
      />
    </v-overlay>
  </transition>
</template>

<script>
import { PixelSpinner as Spinner } from "epic-spinners";

export default {
  name: "DmdLoadingOverlay",
  components: {
    Spinner,
  },

  props: {
    absolute: { type: Boolean, default: true },
  },

  computed: {
    spinnerColor() {
      return this.$vuetify.theme.isDark
        ? this.$vuetify.theme.themes.dark.primary
        : this.$vuetify.theme.themes.light.secondary;
    },
  },
};
</script>
