import { RouteConfig } from "vue-router";
import store from "@/store";
import { Route } from "vue-router/types/router";

const Login = () => import(/* webpackChunkName: "login" */ "@/app/user/views/Login.vue");

export const authRoutes: RouteConfig[] = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/auth/callback",
    name: "auth-callback",
    component: Login,
    props: (route: Route) => ({
      pkceCode: route.query.code,
    }),
  },
];

export const impersonationRoutes: RouteConfig[] = [
  {
    path: "/auth/impersonate",
    name: "auth-impersonate",
    component: () => import("@/app/user/views/Impersonate.vue"),
    beforeEnter(to, from, next) {
      if (["ADMIN", "TEAM_MEMBER"].includes(store.state?.auth?.user?.role)) {
        next();
      } else {
        next("/home");
      }
    },
  },
];
