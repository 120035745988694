/* eslint-disable no-param-reassign */
export default {
  namespaced: true,

  state() {
    return {
      loading: false,
      data: {},
    };
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setData(state, data) {
      state.data = data;
    },
  },
};
