import Vue from "vue";
import Vuetify, { VBtn, VList } from "vuetify/lib";
import { Scroll } from "vuetify/lib/directives";
import i18n from "@/plugins/i18n";

Vue.use(Vuetify, {
  components: {
    VList,
    VBtn,
  },
  directives: { Scroll },
});

const vuetify = new Vuetify({
  iconfont: "mdi",
  theme: {
    themes: {
      light: {
        primary: "#0082C3",
        secondary: "#80b0ff",
        accent: "#ec6607",
        error: "#db3f52",
        info: "#AB47BC",
        success: "#417505",
        warning: "#e6a23c",
      },
      dark: {
        primary: "#0082C3",
        secondary: "#80b0ff",
        accent: "#ec6607",
        error: "#db3f52",
        info: "#bb86fc",
        success: "#417505",
        warning: "#e6a23c",
      },
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string,
  },
});

export default vuetify;

export function useVuetify() {
  return vuetify.framework;
}
