import axios, { AxiosError } from "axios";
import qs from "qs";
import store from "@/store";
import router from "@/router";

const http = axios.create({
  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: "brackets",
      skipNulls: true,
      serializeDate: (d) => d.toISOString(),
      encode: process.env.NODE_ENV === "production",
      encodeValuesOnly: true,
    }),
});

/**
 * @param {string} fedToken
 */
async function intercept401(error: AxiosError) {
  if (error.response && error.response.status === 401) {
    if (router.currentRoute.name !== "login") {
      localStorage.setItem(
        "login_redirect",
        `/${window.location.href
          .replace(/https?:\/\//, "")
          .split("/")
          .splice(1)
          .join("/")}`
      );
      await router.replace({ name: "login" });
    }
  }
  return Promise.reject(error);
}

http.interceptors.request.use(
  (req) => {
    if (store.state.userPreferences.devMode === true) {
      // eslint-disable-next-line no-param-reassign
      req.headers["x-dev-mode"] = "true";
    }
    return req;
  },
  (r) => r
);

http.interceptors.response.use((r) => r, intercept401);

export default http;
