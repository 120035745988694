import Vue from "vue";
import VueI18n, { DateTimeFormat, LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

const dateTimeFormat: DateTimeFormat = {
  extraShort: {
    year: "2-digit",
    month: "2-digit",
    day: "numeric",
  },
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  shortNoYear: {
    month: "short",
    day: "numeric",
  },
  shortWTime: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  long: {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
  },
  longWoTime: {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "long",
  },
  monthYear: {
    year: "2-digit",
    month: "short",
  },
  hourMinute: {
    hour: "numeric",
    minute: "numeric",
  },
};

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("../locales", false, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  dateTimeFormats: {
    en: dateTimeFormat,
    fr: dateTimeFormat,
  },
});

export default i18n;

export function useI18n() {
  return i18n;
}
