import { RouteConfig } from "vue-router";

const communityRoutes: RouteConfig[] = [
  {
    path: "/community",
    name: "community",
    component: () =>
      import(/* webpackChunkName: "community" */ "@/app/kpi/community/views/Community.vue"),
    meta: {
      title: "app.navigation.community",
      group: "community",
    },
  },
  {
    path: "/community-by-store",
    name: "community-by-store",
    component: () =>
      import(
        /* webpackChunkName: "community-by-store" */ "@/app/kpi/community/views/CommunityByStore.vue"
      ),
    meta: {
      title: "app.navigation.community_by_store",
      group: "community",
    },
  },
  {
    path: "/community-by-sport",
    name: "community-by-sport",
    component: () =>
      import(
        /* webpackChunkName: "community-by-sport" */ "@/app/kpi/community/views/CommunityBySport.vue"
      ),
    meta: {
      title: "app.navigation.community_by_sport",
      group: "community",
    },
  },
];

export default communityRoutes;
