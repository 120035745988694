/* eslint-disable no-param-reassign */
export default {
  namespaced: true,

  state() {
    return {
      loading: false,
      data: {
        carts: 0,
        avgQuantity: 0,
        avgPrice: 0,
        totalAmount: 0,
        os: {},
        channel: {},
      },
    };
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setData(state, data) {
      state.data = data;
    },
  },
};
