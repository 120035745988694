import VueWait from "vue-wait";
import Vue from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $wait: VueWait;
  }
}

declare module "vue/types/options" {
  export interface ComponentOptions<
    V extends Vue,
    Data = DefaultData<V>,
    Methods = DefaultMethods<V>,
    Computed = DefaultComputed,
    PropsDef = PropsDefinition<DefaultProps>,
    Props = DefaultProps
  > {
    wait?: VueWait;
  }
}

Vue.use(VueWait);

const $wait = new VueWait();
export default $wait;

export function useWait() {
  return $wait;
}
